<template>
  <div>
    <table v-if="value && value!==null">
      <!-- {{enumsMap}} {{enums}} -->
      <tr v-if="showOnlyValue===false">
        <td v-for="e in enums" :key="e.value">
          <v-icon v-if="value===e.value" color="primary" :title="enumsMap[e.value]">{{'mdi-alpha-'+e.value.charAt(0).toLowerCase()+'-box'}}</v-icon>
          <v-icon v-else :title="enumsMap[e.value]">{{'mdi-alpha-'+e.value.charAt(0).toLowerCase()+'-box-outline'}}</v-icon>
        </td>             
      </tr>
      <tr v-else>
        <td>
          <v-icon color="primary" :title="enumsMap[value]">{{'mdi-alpha-'+value.charAt(0).toLowerCase()+'-box'}}</v-icon>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

export default {
  name: "EnumVisualization",
  props: {
    value: {
      type: String,
    },
    enums: {
      type: Array,
    },
    enumsMap: {
      type: Object,
    },
    showOnlyValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    };
  },
};
</script>